import React from 'react';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import CTAForm from './CTAForm';
import { withHidePopUpButton } from './CTAHidePopUpButtonHOC';
import { withFormData } from './CTAFormDataHOC';
import './CTAFormBlock.scss';


export const FormBlock = ({ additionalFields, data }) => (
  <div className='cta-block'>
    <ContentBox>
      <CTAForm additionalFields={additionalFields} className='cta-block-form' data={data} />
      <div className='cta-block__disclaimer'>{data.disclaimer}</div>
    </ContentBox>
  </div>
);

export default withHidePopUpButton(withFormData(FormBlock), { bottom: -10 });
