import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import { FORM_ERROR } from 'final-form';
import { exposeMetrics } from 'react-metrics';
import { Form } from 'react-final-form';
import { PrimaryButton } from 'components/Shared/Button/Button';
import FormField from 'components/Shared/Form/FormField/FormField';
import { 
  HS_FORM_TYPES,
  sendHubSpotFormSubmission,
  validatePromoCode,
} from 'components/Shared/FormHandlers';
import Validators, { composeValidators } from 'components/Shared/Form/validators';
import { getHasFullAccess } from 'components/IpCheckContainer/IpCheckContainer';
import { getZip } from 'components/ZipGateContainer/ZipGateContainer';
import { FIELD_TYPES } from '../Shared/Form/FormField/FormField';
import IconExclamation from 'static/images/icons/exclamation-circle.inline.svg';
import IconThumbsUp from 'static/images/icons/icon-thumbs-up.inline.svg';
import './CTAForm.scss';


const validatePromo = async value => {
  if(value && value.length) {
    let res = await validatePromoCode(value);
    if(res !== undefined) {
      res = {}
      res['promo_code'] = 'Code is not valid';
      return res;
    }
  }
}

class CTAForm extends Component {
  constructor(props) {
    super(props);
    this.decorators = [];
    this.validators = {
      name: composeValidators(
        Validators.required(),
        Validators.text()
      ),
      phone: composeValidators(
        Validators.required(),
        Validators.phone()
      ),
      email: composeValidators(
        Validators.required(),
        Validators.email()
      )
    };
    this.state = {
      submittedName: null
    }
    this.form = null;
  }

  componentDidUpdate(prevProps) {
    if(prevProps.showPopUp !== this.props.showPopUp && !this.props.showPopUp && this.state.submittedName) {
      this.setState({ submittedName: null });
    }
  }

  reset = () => {
    this.form.form.reset();
    this.form.form.resetFieldState('name');
    this.form.form.resetFieldState('phone');
    this.form.form.resetFieldState('email');
    this.form.form.resetFieldState('promo_code');
  }

  submit = async values => {
    const additionalFields = this.props.additionalFields || {};
    try {
      const { zip } = getZip();
      const service_available = getHasFullAccess() ? 'true' : 'false';
      await sendHubSpotFormSubmission({ ...values, ...additionalFields, zip, service_available }, HS_FORM_TYPES.CTA);
      this.props.metrics.track('header_popup_form_submit', {});
      this.setState({submittedName: values.name});
      return {};
    } catch (e) {
      window.Sentry.captureException(e);
      this.props.metrics.track('header_popup_form_submit_error');
      return { [FORM_ERROR]: this.props.data.submissionError || 'Form submitting failed' };
    }
  };

  validate = values => {
    const errors = {};
    Object.keys(this.validators).forEach(field => {
      const error = this.validators[field](values[field]);
      if(error !== undefined) {
        errors[field.name] = error;
      }
    });
    return Object.keys(errors).length ? errors : validatePromo(values['promo_code']);
  }

  render() {
    const { data } = this.props;
    const validators = this.validators;
    return (
      <div className={classnames('cta-form', this.props.className, {
        submitted: this.state.submittedName
      })}>
        <div className='cta-form__title'>{data.title}</div>
        <div 
          className='cta-form__description'
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
        <Form
          decorators={this.decorators}
          onSubmit={this.submit}
          validate={this.validate}
          ref={(form) => { this.form = form; }}
          render={({ handleSubmit, pristine, submitError, submitting, hasValidationErrors }) => (
            <Fragment>
              <form 
                className="cta-form__container"
                onSubmit={event => {
                  handleSubmit(event).then( (res) => { 
                    if(!res[FORM_ERROR]) {
                      this.reset();
                    }
                  });
                }}
              >
                <div className="cta-form__fields">
                  <FormField 
                    data={{
                      label: 'Name',
                      name: 'name',
                      required: true,
                      type: FIELD_TYPES.text
                    }}
                    validate={validators.name}
                  />
                  <FormField 
                    data={{
                      label: 'Phone',
                      name: 'phone',
                      required: true,
                      type: FIELD_TYPES.phone
                    }}
                    validate={validators.phone}
                  />
                  <FormField 
                    data={{
                      label: 'Email',
                      name: 'email',
                      required: true,
                      type: FIELD_TYPES.email
                    }}
                    validate={validators.email}
                  />
                  <FormField
                    data={{
                      label: 'Promo code',
                      name: 'promo_code',
                      placeholder: 'optional',
                      required: false,
                      type: FIELD_TYPES.text,
                    }} 
                  />
                  <FormField
                    className="ab-field"
                    data={{
                      label: 'Are you a AAA Member?',
                      name: 'aaa_member',
                      options: [
                        {label: 'Yes', value: 'Yes'},
                        {label: 'No', value: 'No'},
                      ],
                      required: false,
                      type: FIELD_TYPES.radio,
                    }} 
                  />
                  <FormField
                    className="ab-field"
                    data={{
                      label: 'Contact Preference',
                      name: 'contact_preference',
                      options: [
                        {label: 'Email', value: 'Email'},
                        {label: 'Phone', value: 'Phone'},
                      ],
                      required: false,
                      type: FIELD_TYPES.radio
                    }}
                  />
                </div>
                <div className="cta-form__disclaimer">{data.disclaimer}</div>
                <PrimaryButton
                  className="cta-form__submit-button"
                  disabled={hasValidationErrors || pristine || submitting}
                  type="submit"
                >
                  {data.submitButton}
                </PrimaryButton>
              </form>
              { submitError && (
                <div className="cta-form__submit-error">
                  <div className="icon-box"><IconExclamation className="icon"/></div>
                  <div dangerouslySetInnerHTML={{ __html: submitError }} />
                </div>
              )}
            </Fragment>
          )}
        />
        <div className='cta-success'>
          <div className='cta-success__title'>
            {`${data.success.title} ${this.state.submittedName}!`}
            <IconThumbsUp className='cta-success__thumbs-up' />
          </div>
          <div 
            className='cta-success__description'
            dangerouslySetInnerHTML={{ __html: data.success.description }}
          />
          {this.props.onClose ? (
            <PrimaryButton
              className='cta-success__close-button'
              onClick={this.props.onClose}
            >
              {data.success.closeButton}
            </PrimaryButton>
          ) : (
            <div className="cta-success__logo">
              <img src={data.success.logo.publicURL} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default exposeMetrics(CTAForm);
