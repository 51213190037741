import React, { Component } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import Swipe from 'react-easy-swipe';
import CTAPopUpContext from 'components/Shared/CTAPopUpContext';
import SeparatorWithGradient from 'components/Shared/SeparatorWithGradient/SeparatorWithGradient';
import { PrimaryButton, SecondaryButton } from 'components/Shared/Button/Button';
import CTAForm from './CTAForm';
import { withFormData } from './CTAFormDataHOC';
import crossIcon from 'static/images/icons/icon-close.svg';
import './CTAFormPopUp.scss';


export class FormPopUp extends Component {

  constructor(props) {
    super(props);
    this.formBox = null;
    this.swipeDelta = 0;
  }

  render() { 
    return (
      <CTAPopUpContext.Consumer>
        {({ showPopUp, showPopUpButton, togglePopUp }) => {
          return (
            <React.Fragment>
              <div className={`cta-popup-overlay ${showPopUp ? 'expanded': ''}`} />
                <div className={`cta-popup ${showPopUp ? 'expanded': ''}`}>
                  <OutsideClickHandler
                    onOutsideClick={() => togglePopUp(false)}
                  >
                  <Swipe
                    onSwipeEnd={() => {
                      this.formBox.style.transitionDelay = "";
                      this.formBox.style.transitionDuration = "";
                      if(this.swipeDelta > this.formBox.clientWidth*0.25) {
                        togglePopUp(false); 
                      }
                      this.formBox.style.transform = "";
                      this.swipeDelta = 0;
                    }}
                    onSwipeMove={(position) => {
                      this.swipeDelta = position.x > 0 ? position.x : 0;
                      this.formBox.style.transform = `translateX(${this.swipeDelta}px)`;
                    }}
                    onSwipeStart={() => {
                      this.formBox.style.transitionDelay = "0s";
                      this.formBox.style.transitionDuration = "0.1s";
                    }}
                  >
                    <div className='cta-popup__box' ref={ el => this.formBox = el }>
                      <SecondaryButton
                        className="cta-popup__close-button"
                        onClick={() => togglePopUp(false)}
                      >
                        <img src={crossIcon} />
                      </SecondaryButton>
                      <CTAForm 
                        additionalFields={this.props.additionalFields}
                        data={this.props.data}
                        showPopUp={showPopUp}
                        onClose={() => togglePopUp(false)}
                      />
                      <SeparatorWithGradient />
                    </div>
                  </Swipe>
                </OutsideClickHandler>
              </div>
              <div className={`cta-popup-button ${showPopUpButton ? 'expanded' : ''}`}>
                <PrimaryButton onClick={() => togglePopUp(true)}>
                  <span className='cta-popup-button__title'>{this.props.data.callPopUp}</span>
                </PrimaryButton>
              </div>
            </React.Fragment>
          );
        }}
      </CTAPopUpContext.Consumer>
    );
  }
}

export default withFormData(FormPopUp);
