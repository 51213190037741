import React, { Component } from 'react';
import CTAPopUpContext from 'components/Shared/CTAPopUpContext';
import VisibilitySensor from 'react-visibility-sensor';

export const withHidePopUpButton = ( WrappedComponent, offset={} ) => {
  return class CTAHidePopUpButtonHOC extends Component {
    render() {
      return (
        <CTAPopUpContext.Consumer>
          {({ showPopUpButton, togglePopUpButton }) => (
            <VisibilitySensor
              intervalCheck={false}
              onChange={isVisible => {
                if(isVisible === showPopUpButton) {
                  togglePopUpButton(!showPopUpButton);
                }
              }}
              offset={this.props.offset || offset}
              partialVisibility={true}
              scrollCheck
              scrollThrottle={1}
            >
              <WrappedComponent {...this.props } />
            </VisibilitySensor>
          )}
        </CTAPopUpContext.Consumer>
      );
    }
  }
};
