import React, { Fragment } from 'react';
import CTAForm from './CTAForm';
import CTAFormBlock, { FormBlock } from './CTAFormBlock';
import CTAFormPopUp, { FormPopUp } from './CTAFormPopUp';
import { withHidePopUpButton } from './CTAHidePopUpButtonHOC';


const CTAFull = ({additionalFields}) => {
  return (
    <Fragment>
      <CTAFormBlock additionalFields={additionalFields} />
      <CTAFormPopUp additionalFields={additionalFields} />
    </Fragment>
  );
}

export default CTAFull;

export {
  CTAForm,
  CTAFormBlock,
  CTAFormPopUp,
  FormBlock, 
  FormPopUp,
  withHidePopUpButton,
};
