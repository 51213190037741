import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

export const withFormData = (WrappedComponent) => {
    return class CTAFormDataHOC extends React.Component {
      render() {
        return (
          <StaticQuery
            query={graphql`
              query {
                ctaFormYaml {
                  title
                  description
                  submitButton
                  submissionError
                  callPopUp
                  success {
                    title
                    description
                    closeButton
                    logo {
                      publicURL
                    }
                  }
                  disclaimer
                }
              }
            `}
            render={data => <WrappedComponent data={data.ctaFormYaml} {...this.props} />}
          />
        );
      }
    }
  }
